.btn-loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #FF7A00;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
    margin: 0 auto;
}

.card-loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #FF7A00;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

.comment-loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #FF7A00;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
    margin: 0 auto;
}

.video-chanel-loader-container {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.video-chanel-loader {
  border: 7px solid #f3f3f3;
  border-top: 7px solid #FF7A00;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

.page-loader-container {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.page-loader {
  border: 7px solid #f3f3f3;
  border-top: 7px solid #FF7A00;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }