/* video */

.video-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    width: 90%;
    margin: 0 auto;
  }
  
  .video-wrapper {
    padding-bottom: 10px;
  }
  
  .video-player {
    width: 100%!important;
  }
  
  .video-title {
    font-family: Avenir, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 101.5%;
    /* or 18px */
    color: #FFFFFF;
  }
  
  
  .video-description-wrapper {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  
  .video-description-header {
    display: grid;
    grid-template-columns: 100px 1fr 1fr;
    padding: 20px 0;
    border-bottom: 1px solid #C2C2C2;
    border-top: 1px solid #C2C2C2;
  
  }
  
  
  
  .video-username span {
    display: block;
    margin-left: 20px;
  }
  
  .content-creator-username {
    font-family: Avenir, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 101.5%;
    /* or 24px */
    color: #FFFFFF;
    margin-bottom: 10px;
  }
  
  .count-subscriber {
    font-family: Avenir, sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 101.5%;
    color: #F0F0F0;
  }
  
  .video-description-header img {
    width: 100%;
    height: 100px;
    object-fit: cover;
  }
  
  .button-wrapper {
    align-self: center;
    justify-self: flex-end;
  }
  
  .btn-subscribe {
    cursor: pointer;
    display: block;
    padding: 10px 20px;
    background: #FF7A00;
    border-radius: 5px;
    border: none;
    font-family: Avenir, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #FFFFFF;
  }

  .btn-unsubscribe {
    cursor: pointer;
    display: block;
    padding: 10px 20px;
    background:rgba(210, 210, 210, 0.25);
    border-radius: 5px;
    border: none;
    font-family: Avenir, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #FFFFFF;

  }

  .btn-subscribe:hover {
    background: rgba(210, 210, 210, 0.25);
    border: 2px solid #BCBCBC;
    border-radius: 5px;
    color: #ffffff;
    transition: background 0.2s ease-in-out;
  }
  
  .video-description-body {
    border-bottom: 1px solid #C2C2C2;
    margin-bottom: 30px;
    padding-bottom: 20px;
  }
  
  .video-description-body p {
    font-family: Avenir, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    text-align: justify;
    color: #FFFFFF;
  }
  
  .recomendation-video {
    display: grid;
    justify-items: flex-end;
    row-gap: 20px;
    margin-bottom: 20px;
    height: fit-content;
  }
  
  .recomendation-video .card {
    width: 80%;
    padding-bottom: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid #aaaaaa;
  }

  .recomendation-video .card:last-child {
    border-bottom: none;
  }

  .empty-video {
    font-family: Avenir, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
  }

  .create-now {
    font-family: Avenir, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: #FF7A00;
  }


  .create-now:hover {
    color: #555555;
  }


  /* video info */

  .video-info {
    display: grid;
    width: 50%;
    grid-template-columns: 50px 50px 1fr;
    align-items: center;
    column-gap: 20px;
  }

  

  .info-item {
    display: grid;
    grid-template-columns: 30px 1fr;
    align-items: center;
  }

  .info-item span {
    font-family: Avenir, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 101.5%;
    color: #aaaaaa;
    margin-right: 20px;
  }

  .info-item img {
    margin-right: 5px;
    width: 20px;
    height: 20px
  }

  .info-item button {
    box-sizing: border-box;
    background: transparent;
    padding-top: 5px;
    padding-left: 0;
    border: none;
    cursor: pointer;
  }

  .info-item button:focus{
    outline: none;
  }

  .info-item button img {
    width: 20px;
    height: 20px;
  }

  /* end video info */
  
  
  /* end video */