.modal-container {
    position: fixed;
    display: grid;
    justify-items: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    opacity: 0;
    
}

.modal-container-subscribe {
    position: fixed;
    display: grid;
    bottom: 0;
    left: 0;
    z-index: 99;
    opacity: 0;   
}

.show {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.close {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.modal-box {
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: border-box;
    width: 50%;
    background: #151515;
    border-radius: 5px;
    border: 1px solid #ffffff; 
}

.modal-header {
    background: #FF7A00;
    padding: 10px 20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.modal-header h1 {
    font-family: Avenir, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0;
}

.modal-body {
    background: #151515;
    padding: 10px 20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.modal-body p {
    text-align: center;
    font-family: Avenir, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #ffffff;
    padding: 0;
}

.modal-footer {
    padding: 10px 20px;
    text-align: right;
    border-top: 1px solid #BCBCBC;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.modal-footer button {
    padding: 8px 20px;
    background: #464545;
    border-radius: 5px;
    border: none;
    font-family: Avenir, sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    color: #FFFFFF;
    margin-left: 20px;
    cursor: pointer;
    
}

.modal-footer button.btn-del {
    background: #ff0000;
}

.modal-footer button:hover {
    background: #464545;
    border-radius: 5px;
    color: #ffffff;
    transition: background 0.2s ease-in-out;
}


.modal-box-subscribe {
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: border-box;
}

.modal-header-subscribe {
    transform: translate(50px, -50px);
    background: #FF7A00;
    padding: 10px 20px;
    border-radius: 5px;
    text-align: center;
}

.modal-header-subscribe h1 {
    font-family: Avenir, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0;
}

