/* card */
.card-thumbnail-container {
  display: grid;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 200px;
  background: #9b9a9a;
  border-radius: 5px;
  position: relative;
}

.card-thumbnail {
  width: 100%;
  height: 200px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 5px;
  transition: transform 0.5s;
}

.card-thumbnail:hover {
  border-radius: 5px;
  transform: scale(1.3);
}

.card-title {
  font-family: Avenir, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  /* or 14px */
  color: #FFFFFF;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}



.card-username {
  font-family: Avenir, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 101.5%;
  /* or 12px */
  color: #aaaaaa;
  margin-bottom: 5px;
}

.card-title:hover, .card-username:hover {
  color: #FF7A00;
  transition: color 0.3s ease;
}



.card span {
  font-family: Avenir, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 101.5%;
  /* or 12px */
  color: #aaaaaa;
  margin-right: 20px;
}


.btn-delete {
  display: grid;
  position: absolute;
  width: 32px;
  height: 32px;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  /* transform: translate(215px, -200px); */
  z-index: 99;
  top: 0;
  right: 0;
}

.btn-delete img {
  width: 100%;
  height: 100%;
}

.btn-delete:hover {
  width: 35px;
  height: 35px;
  transition: all 0.4s;
}




/* end card */