/* register login*/

.landing-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: #ffffff;
    justify-items: center;
    align-items: center;
    width: 100%;
    height: 100vh;
  }
  
  .landing-welcome {
    justify-self: flex-end;
    margin-right: 100px;
  
  }
  
  .landing-welcome img {
    display: block;
    margin-bottom: 61px;
  }
  
  .button {
    cursor: pointer;
    display: block;
    padding: 13px 40px;
    background: #FF7A00;
    border-radius: 5px;
    border: none;
    font-family: Avenir, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #FFFFFF;
  }

  .button:hover {
    background: rgba(210, 210, 210, 0.25);
    border: 2px solid #BCBCBC;
    border-radius: 5px;
    color: #ffffff;
    transition: background 0.2s ease-in-out;
  }
  
  .landing-form {
    margin-left: 100px;
    justify-self: flex-start;
    background: #161616;
    border-radius: 10px;
    width: 416px;
    padding: 20px 33px 44px 33px;
    box-sizing: border-box;
    height: fit-content;
  }
  
  .landing-form input {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    padding: 16px;
    background: rgba(210, 210, 210, 0.25);
    border: 2px solid #BCBCBC;
    box-sizing: border-box;
    border-radius: 5px;
    color: #ffffff;
  }
  
  .landing-form textarea {
    font-family: Avenir, sans-serif;
    display: block;
    width: 100%;
    height: 122px;
    margin-bottom: 20px;
    padding: 16px;
    background: rgba(210, 210, 210, 0.25);
    border: 2px solid #BCBCBC;
    box-sizing: border-box;
    border-radius: 5px;
    color: #ffffff;
  }
  
  .landing-form input:focus, .landing-form textarea:focus {
    color: #ffffff;
    outline: none;
    box-shadow: 0 0 3px 1px #FF7A00;
    border: 2px solid #FF7A00;
  }
  
  .landing-form button {
    width: 100%;
  }
  
  /* end register login */


.input-validation {
    display: block;
    margin-top: -10px;
    width: fit-content;
    margin-bottom: 20px;
    padding: 10px 16px;
    font-size: 16px;
    background: #ff0000;
    box-sizing: border-box;
    color: #ffffff;
    border-radius: 5px;
    animation: showErrorAlert 0.2s ease;
    
}


.validation-triangle {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 30px solid #ff0000;
    transform: translate(-10px, -30px);
}

.success-info {
  background-color:#FF7A00;
  border-radius: 5px;
  box-sizing: border-box;
  text-align: center;
  padding: 10px 0;
  margin-bottom: 20px;
  color: #ffffff;
  animation: showErrorAlert 0.2s ease;
}

.error-info {
  background-color:#ff0800;
  border-radius: 5px;
  box-sizing: border-box;
  text-align: center;
  padding: 10px 0;
  color: #ffffff;
  margin-bottom: 20px;
  animation: showErrorAlert 0.2s ease;
}

.success-info p, .error-info p {
  padding: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 5px;
}


@keyframes showErrorAlert {
    from {
        transform: translateY(-10px);
    }
    to {
        transform: translateY(0);
    }
}