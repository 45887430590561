.progress-container {
    position: fixed;
    display: grid;
    justify-items: center;
    align-items: center;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.7);
    
}

.progress-bar-container {
    position: relative;
    background: rgba(210, 210, 210, 0.25);
    border: 2px solid #BCBCBC;
    width: 50%;
    text-align: center;
    color: #ffffff;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    border-radius: 5px;
    overflow: hidden;
    box-sizing: border-box;
}

.progress-bar{
    position: absolute;
    height: 100%;
    left: 0;
    background:  #FF7A00;
    transition: width 0.4s;
}

.progress-bar-container span {
    position: relative;
    z-index: 20;
    padding: 10px;
    display: block;
}